<template>
  <div class="panel">
    <div
      v-for="p of columns"
      :key="p.pipeline_id"
      class="col"
    >
      <div class="col-header">
        {{ p.name }}
      </div>
      <!-- <div class="col-body empty" /> -->
      <draggable
        class="col-body"
        :class="{'empty': !p.leads.length}"
        :list="p.leads"
        :sort="false"
        group="leads"
        @change="onChange($event, p)"
      >
        <div
          v-for="(l, index) in p.leads"
          :key="index"
          class="lead"
        >
          <div class="lead-header">
            <b>#{{ idFormat(l.pipeline_lead_id) }} - {{ l.name }}</b>
            <small><b>{{ l.email }}</b></small>
          </div>

          <div class="agent">
            <md-icon class="md-sm">
              support_agent
            </md-icon>
            <p>{{ l.agent.name }}</p>
          </div>

          <div class="source">
            <p>Source:</p>
            <div class="badge badge-primary">
              {{ l.campaign.domain }}
            </div>
          </div>

          <div class="segment">
            <p>Segment:</p>
            <div class="badge badge-success">
              {{ l.segment.name }}
            </div>
          </div>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  components: {
    draggable,
  },
  data: () => ({
    loading: false,
    columns: [],
  }),
  mounted() {
    this.getPipelineLeads();
  },
  methods: {
    getPipelineLeads() {
      this.loading = true;
      this.request('crm/pipeline_leads', 'get', {
        per_page: 1000,
      }, ({ data }) => {
        this.columns = data;
      }, () => {
        this.loading = false;
      });
    },
    onChange(e, list) {
      if (e.added) {
        this.request(`crm/pipeline_leads/${e.added.element.pipeline_lead_id}/status`, 'put', {
          pipeline_id: list.pipeline_id,
        }, () => {
          // eslint-disable-next-line no-console
          console.log('status updated');
        });
      }
    },
    idFormat(id) {
      return `00000${id}`.substr(-5);
    },
  },
};
</script>

<style scoped lang="scss">
.panel {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  overflow-x: auto;
  padding-left: .5em;
}
.col {
  padding-left: .5em;
  padding-right: .5em;
  width: 300px;
  height: calc(100vh - 210px);
  overflow-y: auto;

  .col-header {
    font-weight: 600;
    text-transform: uppercase;
    height: 30px;
  }

  .col-body {
    &.empty {
      border-top-right-radius: .5em;
      border-top-left-radius: .5em;
      background: rgba(0,0,0, .2);
      background: linear-gradient(180deg, rgba(0,0,0,.2) 0%, rgba(0,0,0,0) 100%);
      height: calc(100% - 30px);
    }

    .lead {
      width: 100%;
      border-radius: .5em;
      background: rgba(0,0,0, .2);
      padding: .75em;
      margin-bottom: .5em;
      min-height: 100px;
      border: 1px solid rgba(0,0,0, .2);
      cursor: pointer;

      &:hover {
        border: 1px solid rgba(0,0,0, .5);
      }

      &-header {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: .5em;

        small {
          line-height: .8em;
        }
      }

      .agent, .source, .segment {
        display: flex;
        justify-content: center;
        font-size: .9em;
        margin-bottom: .5em;

        p {
          margin: 0;
          flex-grow: 1;
        }
      }

      .source, .segment{
        flex-direction: column;
        align-items: flex-start;
        p {
          font-size: .75em;
        }
        .badge {
          text-transform: uppercase;
        }
      }
    }
  }
}
</style>
